import React from 'react';
import { Divider, Form, Input, Button, Checkbox, Typography } from 'antd';
import { AsyncSelect } from 'components/Select';
import { validateDates } from 'utils';
import AriaDatePicker from 'components/AriaDatePicker';

const { Title } = Typography;

const AccreditationSection = ({ applicationSubmitted, onDeleteAccreditation, typeOfCareId }) => {
  const form = Form.useFormInstance();

  return (
    <Form.List name="accreditations">
      {(accreditations, { add, remove }) => {
        return (
          <div>
            {accreditations.map((field, index) => {
              return (
                <div key={`ack:${index}`}>
                  <Divider />
                  <Title level={2} className="!mt-4 !mb-6">
                    Accreditation {index + 1}
                  </Title>

                  <AccreditationItem
                    key={field.key}
                    field={field}
                    index={index}
                    form={form}
                    remove={remove}
                    accreditations={accreditations}
                    applicationSubmitted={applicationSubmitted}
                    onDeleteAccreditation={onDeleteAccreditation}
                    typeOfCareId={typeOfCareId}
                  />
                </div>
              );
            })}

            <Divider />
            <Button
              onClick={() => {
                add();
              }}
              style={{ borderRadius: '2px' }}
            >
              Add another accreditation
            </Button>
          </div>
        );
      }}
    </Form.List>
  );
};

const AccreditationItem = React.memo(
  ({ field, index, form, remove, accreditations, applicationSubmitted, onDeleteAccreditation, typeOfCareId }) => {
    const neverExpires = Form.useWatch(['accreditations', index, 'neverExpires']);

    const handleRemove = () => {
      const accreditation = form.getFieldValue(['accreditations', index]);
      if (accreditation?.id) {
        onDeleteAccreditation(accreditation.id);
      }
      remove(field.name);
    };

    return (
      <div>
        <Form.Item
          name={[index, 'type']}
          label="NAME OF ACCREDITING BODY"
          rules={[{ required: true, message: 'Accrediting body is required' }]}
          className="max-w-96"
        >
          <AsyncSelect
            ariaLabel="Accreditation Type"
            optionsApiUrl={`/accreditation-types?typeOfCare=${typeOfCareId}`}
            getOptionLabel={(option) =>
              option.description ? `${option.description}${option.title ? ` (${option.title})` : ''}` : option.title
            }
            disabled={applicationSubmitted}
          />
        </Form.Item>

        <Form.Item name={[index, 'accreditationNumber']} label="Credential Number" className="max-w-96">
          <Input data-testid="accreditationNumber" />
        </Form.Item>

        <Form.Item
          name={[index, 'startDate']}
          label="Start date"
          rules={[
            {
              validator: validateDates(
                form.getFieldValue,
                ['accreditations', index, 'startDate'],
                ['accreditations', index, 'endDate'],
                'Start date must be before expiration date',
              ),
            },
          ]}
          className="max-w-96 mb-4"
        >
          <AriaDatePicker disabled={applicationSubmitted} />
        </Form.Item>

        <Form.Item className="mb-4 mt-5" name={[index, 'neverExpires']} valuePropName="checked">
          <Checkbox>This accreditation does not expire</Checkbox>
        </Form.Item>

        {!neverExpires && (
          <Form.Item
            label="Expiration date"
            name={[index, 'endDate']}
            rules={[
              {
                validator: validateDates(
                  form.getFieldValue,
                  ['accreditations', index, 'startDate'],
                  ['accreditations', index, 'endDate'],
                  'Expiration date must be after start date',
                ),
              },
            ]}
            className="max-w-96"
          >
            <AriaDatePicker disabled={neverExpires || applicationSubmitted} />
          </Form.Item>
        )}

        {accreditations.length > 1 && !applicationSubmitted ? (
          <Button className="mb-4" onClick={handleRemove} style={{ borderRadius: '2px' }}>
            Remove
          </Button>
        ) : null}
      </div>
    );
  },
);

export default AccreditationSection;
